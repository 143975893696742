import React, { useState, useEffect, useRef, useCallback, useReducer, useLayoutEffect } from 'react';

const colorPalette = [
  '#3498db', '#2ecc71', '#e74c3c', '#f39c12', '#9b59b6',
  '#1abc9c', '#34495e', '#16a085', '#27ae60', '#2980b9',
  '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22', '#95a5a6'
];

function ColorPicker({ color, onChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const pickerRef = useRef(null);
  
    useEffect(() => {
      function handleClickOutside(event) {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div className="color-picker-container" ref={pickerRef}>
        <button
          className="color-picker-toggle small-color-circle"
          style={{ backgroundColor: color }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? '▲' : '▼'}
        </button>
        {isOpen && (
          <div className="color-picker">
            {colorPalette.map((c) => (
              <button
                key={c}
                className={`color-swatch small-color-circle ${c === color ? 'selected' : ''}`}
                style={{ backgroundColor: c }}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(c);
                  setIsOpen(false);
                }}
                type="button"
              />
            ))}
          </div>
        )}
      </div>
    );
}

// Custom hook for handling clicks outside of a component
function useClickOutside(ref, handler) {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
}

function EditColorPicker({ color, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const pickerRef = useRef(null);
  const toggleButtonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpen &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleToggleClick = useCallback((e) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation();
    setIsOpen(prevIsOpen => !prevIsOpen);
  }, []);

  return (
    <div className="color-picker-container" ref={pickerRef}>
      <button
        type="button" // Explicitly set type to button
        className="color-picker-toggle"
        style={{ backgroundColor: color }}
        onClick={handleToggleClick}
        ref={toggleButtonRef}
      >
        {isOpen ? '▲' : '▼'}
      </button>
      {isOpen && (
        <div className="color-picker">
          {colorPalette.map((c) => (
            <button
              key={c}
              type="button" // Explicitly set type to button
              className={`color-swatch ${c === color ? 'selected' : ''}`}
              style={{ backgroundColor: c }}
              onClick={(e) => {
                e.preventDefault(); // Prevent form submission
                e.stopPropagation();
                onChange(c);
                setIsOpen(false);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function getRandomColor() {
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
}

function getContrastColor(hexColor) {
  // Default to black if no color is provided
  if (!hexColor) return '#000000';
  
  // Remove the '#' if it's present
  hexColor = hexColor.replace('#', '');
  
  // If the color is not a valid 6-digit hex, return black
  if (!/^[0-9A-F]{6}$/i.test(hexColor)) return '#000000';

  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#000000' : '#ffffff';
}

function Accounts({ user, supabase }) {
  const [accounts, setAccounts] = useState([]);
  const [newAccount, setNewAccount] = useState({ name: '', description: '', color: colorPalette[0], type: 'personal' });
  const [editingAccount, setEditingAccount] = useState(null);
  const [typeFilter, setTypeFilter] = useState('all');

  useEffect(() => {
    fetchAccounts();
  }, []);

  async function fetchAccounts() {
    const { data, error } = await supabase
      .from('accounts')
      .select('*')
      .eq('user_id', user.id);

    if (error) console.error('Error fetching accounts:', error);
    else setAccounts(data || []);
  }

  async function addAccount(e) {
    e.preventDefault();
    const { data, error } = await supabase
      .from('accounts')
      .insert({ ...newAccount, user_id: user.id })
      .select();

    if (error) console.error('Error adding account:', error);
    else {
      setAccounts([...accounts, data[0]]);
      setNewAccount({ name: '', description: '', color: colorPalette[0], type: 'personal' });
    }
  }

  async function updateAccount(updatedAccount) {
    const { error } = await supabase
      .from('accounts')
      .update(updatedAccount)
      .eq('id', updatedAccount.id);

    if (error) console.error('Error updating account:', error);
    else {
      setAccounts(accounts.map(account => account.id === updatedAccount.id ? updatedAccount : account));
      setEditingAccount(null);
    }
  }

  const EditAccountForm = React.memo(({ editingAccount, updateAccount, setEditingAccount }) => {
    const [formState, setFormState] = useState(editingAccount);

    const handleSubmit = useCallback((e) => {
      e.preventDefault();
      updateAccount(formState);
      setEditingAccount(null);
    }, [formState, updateAccount, setEditingAccount]);

    const handleColorChange = useCallback((color) => {
      setFormState(prev => ({ ...prev, color }));
    }, []);

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormState(prev => ({ ...prev, [name]: value }));
    }, []);

    return (
      <form onSubmit={handleSubmit}>
        <div className="color-select-container">
          <EditColorPicker
            color={formState.color || '#3498db'}
            onChange={handleColorChange}
          />
        </div>
        <input
          type="text"
          name="name"
          value={formState.name || ''}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="description"
          value={formState.description || ''}
          onChange={handleInputChange}
          placeholder="Description"
        />
        <select
          name="type"
          value={formState.type || 'personal'}
          onChange={handleInputChange}
        >
          <option value="personal">Personal</option>
          <option value="work">Work</option>
          <option value="customer">Customer</option>
          <option value="investor">Investor</option>
          <option value="partner">Partner</option>
          <option value="other">Other</option>
        </select>
        <button type="submit">Save</button>
        <button type="button" onClick={() => setEditingAccount(null)}>Cancel</button>
      </form>
    );
  });

  const filteredAccounts = accounts.filter(account => 
    typeFilter === 'all' || account.type === typeFilter
  );

  return (
    <div className="accounts">
    <form onSubmit={addAccount} className="account-form">
    <div className="form-row">
        <div className="color-select-container">
        <ColorPicker
            color={newAccount.color || '#3498db'}
            onChange={(color) => setNewAccount({ ...newAccount, color })}
        />
        </div>
        <input
        type="text"
        value={newAccount.name || ''}
        onChange={(e) => setNewAccount({ ...newAccount, name: e.target.value })}
        placeholder="Account name"
        required
        className="account-input"
        />
        <input
        type="text"
        value={newAccount.description || ''}
        onChange={(e) => setNewAccount({ ...newAccount, description: e.target.value })}
        placeholder="Description"
        className="account-input"
        />
    </div>
    <div className="form-row">
        <select
        value={newAccount.type || 'personal'}
        onChange={(e) => setNewAccount({ ...newAccount, type: e.target.value })}
        className="account-select"
        >
        <option value="personal">Personal</option>
        <option value="work">Work</option>
        <option value="customer">Customer</option>
        <option value="investor">Investor</option>
        <option value="partner">Partner</option>
        <option value="other">Other</option>
        </select>
        <button type="submit" className="add-account-button">Add</button>
    </div>
    </form>
      <div className="filter-container">
        <select
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
          className="type-filter"
        >
          <option value="all">All Types</option>
          <option value="personal">Personal</option>
          <option value="work">Work</option>
          <option value="customer">Customer</option>
          <option value="investor">Investor</option>
          <option value="partner">Partner</option>
          <option value="other">Other</option>
        </select>
      </div>
      <ul>
        {filteredAccounts.map(account => (
          <li key={account.id}>
            {editingAccount && editingAccount.id === account.id ? (
              <EditAccountForm
                key={editingAccount.id}
                editingAccount={editingAccount}
                updateAccount={updateAccount}
                setEditingAccount={setEditingAccount}
              />
            ) : (
              <>
                <div className="color-indicator" style={{ backgroundColor: account.color || '#3498db' }}></div>
                <span className="account-tag" style={{ backgroundColor: account.color || '#3498db' }}>
                  {account.name}
                </span>
                <span>{account.description}</span>
                <span>{account.type}</span>
                <button onClick={() => setEditingAccount({...account})}>Edit</button>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Accounts;