import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header({ user, signOut, signInWithGoogle }) {
  const location = useLocation();
  const isTasksPage = !location.pathname.includes('/accounts');

  return (
    <header className="header-container">
      <div className="header-left">
        <img src="/logo.png" alt="Logo" className="header-logo" />
        <h1 className="text-2xl font-bold text-blue-600">
          {user
            ? (isTasksPage ? "Tasks" : "Accounts")
            : "Pratik's Personal Task Manager"}
        </h1>
      </div>
      <div className="header-right">
        {user && (
          <>
            <Link 
              to={isTasksPage ? "/accounts" : "/"} 
              className="text-blue-600 hover:text-blue-800"
            >
              {isTasksPage ? "Accounts" : "Tasks"}
            </Link>
            <span className="text-sm text-gray-600">
              {user.user_metadata?.full_name}
            </span>
            <button 
              onClick={signOut} 
              className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors text-sm"
            >
              Sign Out
            </button>
          </>
        )}
        {!user && (
          <button 
            onClick={signInWithGoogle} 
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors text-sm"
          >
            Sign In with Google
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;