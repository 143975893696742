import React, { useState, useEffect, useRef, useCallback } from 'react';
import { formatDistanceToNow, isPast, differenceInDays, differenceInMonths } from 'date-fns';
import { createClient } from '@supabase/supabase-js';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import './App.css';
import config from './config';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import Accounts from './Accounts';
import Header from './Header';
import { useLongPress } from 'use-long-press';

const supabase = createClient(config.supabaseUrl, config.supabaseKey);

function App() {
  const [user, setUser] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [newTaskDateTime, setNewTaskDateTime] = useState('');
  const [contextMenu, setContextMenu] = useState(null);
  const [editingTask, setEditingTask] = useState(null);
  const editTitleRef = useRef(null);
  const editDateRef = useRef(null);
  const [accounts, setAccounts] = useState([]);
  const [filterAccount, setFilterAccount] = useState(null);
  const [newTaskAccount, setNewTaskAccount] = useState('Home');
  const [accountOptions, setAccountOptions] = useState([]);
  const [newAccountName, setNewAccountName] = useState('');
  const location = useLocation();
  const editAccountRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const [showArchived, setShowArchived] = useState(() => JSON.parse(localStorage.getItem('showArchived') || 'false'));

  const fetchTasks = useCallback(async () => {
    const { data, error } = await supabase
      .from('tasks')
      .select(`
        *,
        accounts (
          name,
          color
        )
      `)
      .eq('archived', showArchived);
  
    if (error) console.error('Error fetching tasks:', error);
    else {
      const sortedTasks = (data || []).sort((a, b) => {
        // First, sort by completion status
        if (a.completed !== b.completed) {
          return a.completed ? 1 : -1;
        }
        // Then, sort by due date
        return new Date(a.end_date) - new Date(b.end_date);
      });
      setTasks(sortedTasks);
    }
  }, [supabase, showArchived]);

  const fetchAccounts = useCallback(async () => {
    const { data, error } = await supabase
      .from('accounts')
      .select('*')
      .eq('user_id', user.id);

    if (error) console.error('Error fetching accounts:', error);
    else {
      setAccounts(data || []);
      const uniqueAccounts = new Set(data.map(account => account.name));
      uniqueAccounts.add('Home'); // Ensure 'Home' is always included
      setAccountOptions(Array.from(uniqueAccounts).sort((a, b) => a === 'Home' ? -1 : b === 'Home' ? 1 : a.localeCompare(b)));
    }
  }, [user]);

  const getAccountColor = (accountName) => {
    const colors = ['#3498db', '#2ecc71', '#e74c3c', '#f39c12', '#9b59b6', '#1abc9c'];
    let hash = 0;
    for (let i = 0; i < accountName.length; i++) {
      hash = accountName.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  };
  
  const filteredTasks = filterAccount
    ? tasks.filter(task => task.accounts.name === filterAccount)
    : tasks;

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
    });
  
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setUser(session?.user ?? null);
      }
    );
  
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      fetchTasks();
      fetchAccounts();
    }
  }, [user, fetchTasks, fetchAccounts, location, showArchived]);

  async function signInWithGoogle() {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });
    if (error) console.error('Error signing in with Google:', error);
  }

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error signing out:', error);
  }

  async function toggleArchiveTask(id, archived) {
    const { error } = await supabase
      .from('tasks')
      .update({ archived: !archived })
      .eq('id', id);
  
    if (error) console.error('Error archiving/unarchiving task:', error);
    else {
      setTasks(tasks.map(task => 
        task.id === id ? { ...task, archived: !archived } : task
      ));
    }
  }

  async function addTask(e) {
    e.preventDefault();
    if (!newTask || !newTaskDateTime) return;

    let accountId;
    const accountName = newTaskAccount === 'new' ? newAccountName : (newTaskAccount || 'Home');
    const { data: existingAccount } = await supabase
      .from('accounts')
      .select('id')
      .eq('name', accountName)
      .eq('user_id', user.id)
      .single();

    if (existingAccount) {
      accountId = existingAccount.id;
    } else {
      const { data: newAccount } = await supabase
        .from('accounts')
        .insert({ name: accountName, user_id: user.id })
        .select()
        .single();
      accountId = newAccount.id;
    }

    const { data, error } = await supabase
      .from('tasks')
      .insert([{ 
        title: newTask, 
        end_date: new Date(newTaskDateTime).toISOString(),
        user_id: user.id, 
        account_id: accountId 
      }])
      .select(`*, accounts (name)`)
      .single();

    if (error) console.error('Error adding task:', error);
    else {
      setTasks([...tasks, data]);
      setNewTask('');
      setNewTaskDateTime('');
      setNewTaskAccount('');
      setNewAccountName('');
      fetchAccounts();
    }
  }

  async function toggleTaskCompletion(id, completed) {
    const { error } = await supabase
      .from('tasks')
      .update({ completed: !completed, completed_at: !completed ? new Date().toISOString() : null })
      .eq('id', id);

    if (error) console.error('Error updating task:', error);
    else {
      const updatedTasks = tasks.map(task => 
        task.id === id ? { ...task, completed: !completed, completed_at: !completed ? new Date().toISOString() : null } : task
      );
      
      // Sort the tasks
      const sortedTasks = updatedTasks.sort((a, b) => {
        // First, sort by completion status
        if (a.completed !== b.completed) {
          return a.completed ? 1 : -1;
        }
        // Then, sort by due date
        return new Date(a.end_date) - new Date(b.end_date);
      });

      setTasks(sortedTasks);
    }
  }

  async function deleteTask(id) {
    const { error } = await supabase
      .from('tasks')
      .delete()
      .eq('id', id);

    if (error) console.error('Error deleting task:', error);
    else {
      setTasks(tasks.filter(task => task.id !== id));
    }
  }

  async function updateTask(id, newTitle, newEndDate, newAccountName, newDescription) {
    let accountId;
    const { data: existingAccount } = await supabase
      .from('accounts')
      .select('id')
      .eq('name', newAccountName)
      .eq('user_id', user.id)
      .single();
  
    if (existingAccount) {
      accountId = existingAccount.id;
    } else {
      const { data: newAccount } = await supabase
        .from('accounts')
        .insert({ name: newAccountName, user_id: user.id })
        .select()
        .single();
      accountId = newAccount.id;
    }
  
    // Convert local time to UTC
    const utcEndDate = new Date(newEndDate).toUTCString();
  
    const { error } = await supabase
      .from('tasks')
      .update({ 
        title: newTitle, 
        end_date: utcEndDate,
        account_id: accountId,
        description: newDescription
      })
      .eq('id', id);
  
    if (error) console.error('Error updating task:', error);
    else {
      setTasks(tasks.map(task => 
        task.id === id ? { ...task, title: newTitle, end_date: utcEndDate, accounts: { name: newAccountName }, description: newDescription } : task
      ));
      setEditingTask(null);
      fetchAccounts();
    }
  }

  function handleContextMenu(e, task) {
    e.preventDefault();
    e.stopPropagation();
    console.log('Context menu triggered', { x: e.clientX, y: e.clientY, task });
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      task: task
    });
  }

  const handleDoubleClick = (event, task) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.target.getBoundingClientRect();
    setContextMenu({
      x: rect.left,
      y: rect.bottom,
      task: task
    });
  };

  const bind = useLongPress((event, { context }) => {
    const rect = event.target.getBoundingClientRect();
    setContextMenu({
      x: rect.left,
      y: rect.bottom,
      task: context
    });
  }, {
    threshold: 500,
    cancelOnMovement: true,
  });

  function formatTimeDelta(date) {
    const now = new Date();
    const taskDate = new Date(date);
    const isPastDate = isPast(taskDate);
    const monthsDiff = Math.abs(differenceInMonths(taskDate, now));
    const daysDiff = Math.abs(differenceInDays(taskDate, now));
  
    let formattedDelta;
    let color;
  
    if (monthsDiff >= 1) {
      formattedDelta = `${monthsDiff}mo${isPastDate ? ' ago' : ''}`;
    } else if (daysDiff > 7) {
      formattedDelta = `${daysDiff}d${isPastDate ? ' ago' : ''}`;
    } else {
      formattedDelta = formatDistanceToNow(taskDate, { addSuffix: true });
    }
  
    if (isPastDate) {
      color = 'red';
    } else if (daysDiff < 1) {
      color = 'pink';
    } else if (daysDiff < 7) {
      color = 'orange';
    } else {
      color = 'green';
    }
  
    return { formattedDelta, color };
  }

  function handleEditClick() {
    setEditingTask(contextMenu.task);
    setContextMenu(null);
    setTimeout(() => editTitleRef.current?.focus(), 0);
  }

  function handleDeleteClick() {
    deleteTask(contextMenu.task.id);
    setContextMenu(null);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      console.log('Click event:', event.target);
      if (contextMenu && !event.target.closest('.context-menu')) {
        console.log('Closing context menu');
        setContextMenu(null);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu]);

  return (
    <div className="App">
      <Header user={user} signOut={signOut} signInWithGoogle={signInWithGoogle} />
      {!user && (
        <div className="flex flex-col items-center justify-center h-screen">
          <img src="/logo.png" alt="Logo" className="landing-logo" />
          <button onClick={signInWithGoogle} className="sign-in-button">
            Sign In with Google
          </button>
        </div>
      )}
      {user && (
        <Routes>
          <Route path="/" element={
            <main>
              {filterAccount && (
                <div className="filter-indicator">
                  <span>Filtered by: {filterAccount}</span>
                  <button onClick={() => setFilterAccount(null)}>Clear Filter</button>
                </div>
              )}

              <form onSubmit={addTask}>
                <div className="form-group">
                  <div className="account-input-group">
                    <select
                      value={newTaskAccount}
                      onChange={(e) => setNewTaskAccount(e.target.value)}
                      className="account-select"
                    >
                      <option value="">Select account</option>
                      {accountOptions.map((account, index) => (
                        <option key={index} value={account}>{account}</option>
                      ))}
                      <option value="new">Add new account</option>
                    </select>
                    {newTaskAccount === 'new' && (
                      <input
                        type="text"
                        value={newAccountName}
                        onChange={(e) => setNewAccountName(e.target.value)}
                        placeholder="Enter new account name"
                        className="new-account-input"
                      />
                    )}
                  </div>
                  <div className="task-input-group">
                    <input
                      type="text"
                      value={newTask}
                      onChange={(e) => setNewTask(e.target.value)}
                      placeholder="New task"
                      required
                      className="task-input"
                    />
                    <div className="date-button-group">
                      <input
                        type="datetime-local"
                        value={newTaskDateTime}
                        onChange={(e) => setNewTaskDateTime(e.target.value)}
                        required
                        className="datetime-input"
                      />
                      <button type="submit" className="add-task-button">Add Task</button>
                    </div>
                  </div>
                </div>
              </form>
              <ul>
                {filteredTasks.map((task) => (
                  <li 
                    key={task.id} 
                    className={`task ${task.completed ? 'completed' : ''}`}
                    {...bind(task)}
                    onDoubleClick={(e) => handleDoubleClick(e, task)}
                    onContextMenu={(e) => handleContextMenu(e, task)}
                  >
                  {editingTask && editingTask.id === task.id ? (
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      updateTask(
                        task.id, 
                        editTitleRef.current.value, 
                        editDateRef.current.value, 
                        editAccountRef.current.value,
                        editDescriptionRef.current.value
                      );
                    }} className="edit-task-form">
                      <input
                        ref={editTitleRef}
                        defaultValue={task.title}
                        className="task-input full-width"
                      />
                      <textarea
                        ref={editDescriptionRef}
                        defaultValue={task.description || ''}
                        className="task-input full-width"
                        placeholder="Add a description"
                      />
                      <div className="edit-task-bottom-row">
                        <input
                          type="datetime-local"
                          ref={editDateRef}
                          defaultValue={format(parseISO(task.end_date), "yyyy-MM-dd'T'HH:mm", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })}
                          className="datetime-input"
                        />
                        <select
                          ref={editAccountRef}
                          defaultValue={task.accounts.name}
                          className="account-select"
                        >
                          {accountOptions.map((account, index) => (
                            <option key={index} value={account}>{account}</option>
                          ))}
                          <option value="new">Add new account</option>
                        </select>
                        {editAccountRef.current?.value === 'new' && (
                          <input
                            type="text"
                            placeholder="Enter new account name"
                            className="new-account-input"
                            onChange={(e) => editAccountRef.current.value = e.target.value}
                          />
                        )}
                        <button type="submit" className="save-button">Save</button>
                        <button type="button" onClick={() => setEditingTask(null)} className="cancel-button">Cancel</button>
                      </div>
                    </form>
                    ) : (
                      <>
                        <div className="task-content">
                          <input
                            type="checkbox"
                            checked={task.completed}
                            onChange={() => toggleTaskCompletion(task.id, task.completed)}
                          />
                          {task.accounts && (
                            <span 
                              className="account-tag" 
                              onClick={() => setFilterAccount(task.accounts.name)}
                              style={{backgroundColor: task.accounts.color || '#3498db'}}
                            >
                              {task.accounts.name}
                            </span>
                          )}
                          <span className="task-title">{task.title}</span>
                        </div>
                        {!task.completed && (
                          <span 
                            className="task-date" 
                            style={{ color: formatTimeDelta(task.end_date).color }}
                          >
                            {formatTimeDelta(task.end_date).formattedDelta}
                          </span>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
              {contextMenu && (
                <div 
                  className="context-menu" 
                  style={{
                    top: contextMenu.y || 0,
                    left: contextMenu.x || 0,
                    position: 'fixed',
                    zIndex: 1000
                  }}
                >
                  <button onClick={handleEditClick}>Edit</button>
                  <button onClick={handleDeleteClick}>Delete</button>
                  <button onClick={() => {
                    toggleArchiveTask(contextMenu.task.id, contextMenu.task.archived);
                    setContextMenu(null);
                  }}>
                    {contextMenu.task.archived ? 'Unarchive' : 'Archive'}
                </button>
                </div>
              )}
              <button 
                onClick={() => {
                  setShowArchived(prevState => {
                    const newState = !prevState;
                    localStorage.setItem('showArchived', JSON.stringify(newState));
                    return newState;
                  });
                }} 
                className={`toggle-archived-button ${showArchived ? 'archived' : 'unarchived'}`}
                aria-label={showArchived ? "Hide Archived Tasks" : "Show Archived Tasks"}
              >
                <span className="material-icons">
                  {showArchived ? 'unarchive' : 'archive'}
                </span>
              </button>
            </main>
          } />
          <Route path="/accounts" element={<Accounts user={user} supabase={supabase} />} />
        </Routes>
      )}
    </div>
  );
}

export default App;